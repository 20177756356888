@import "../../../styles/color-functions.scss";

.quickAction {
  &:before {
    font-family: FontAwesome5Pro;
    content: attr(data-icon);
  }

  cursor: pointer;

  border-radius: 1000px;
  border:none;
  height: 2em;
  width: 2em;

  font-size: 1.2rem; 
  line-height: 2em;
  text-align: center;
  color: color("primary", "base");

  overflow: hidden;
}