@import "../../../../../../styles/font-functions";
@import "../../../../../../styles/color-functions";

.form {
  & > * {
    margin: 10px;
  }
}

.formTitle {
  @include font-setting("xl");
  margin-bottom: 20px;
  grid-column: 1/-1;
}

.disclaimer {
  @include font-setting("base")
}

.instructions {
  @include font-setting("base-bold")
}

.formItems {
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: color("background", "light-alt-2");

  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
  flex-direction: column;

}

.formGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 70px;
}

.itemLabel {
  width: 200px;
}

.radioGroup {
  background-color: white;
  border-radius: 8px;
  padding-block: 10px;
  margin: 5px;
}