@import "../../styles/color-functions";
@import "../../styles/font-classes";

html {
  font-family:   
    "Manrope",
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 100%;
  color: color("text","dark");
  background-color: color("background", "light");
}