$sideNav-width: 300px;
$header-height-mobile: 75px;


.hamburger {
  height:100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-size: 2rem;
  
  cursor: pointer;

  outline: none;
}

.drawerNavBackground {
  position: absolute;
  top: 0;
  left: $sideNav-width;
  
  background-color: #000000c9;
  height: 100vh;
  width: 100vw;

  opacity: 0;
  visibility: hidden;

  transition: opacity 250ms ease-in;
}

.drawerNavContainer {
  position: fixed;
  z-index: 120;
  width: $sideNav-width;
  height: 100vh;
  top: 0;
  left: -$sideNav-width;
  transition: left 250ms ease-in;
  background-color: #3D3D6E;

  outline: none;

  &:focus {
    left: 0;

    .drawerNavBackground {
      opacity: 1;
      visibility: unset;
    }
  }
}

.drawerNavChevron {
  height: $header-height-mobile;
  
  cursor: pointer;

  color: white;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  line-height: $header-height-mobile;
  font-size: 2rem;

}