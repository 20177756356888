@import "font-sizes";

@function text-size($level: "base") {
  @return map-get(map-get($text-settings, $level), 'font-size');
};

@function line-height($level: "base") {
  @return map-get(map-get($text-settings, $level), 'line-height');
};

@mixin font-setting($level: "base", $content: null) {
  @each $name, $style in map-get($text-settings, $level) {
    #{$name}: $style $content;
  }
}