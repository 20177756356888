@import "../../../../../../../../../styles/color-functions";

.form > *:not(:last-child) {
  margin-bottom: 20px;
}

.form > *:nth-last-child(2) {
  margin-bottom: 40px;
}

.formGroup {
  display: flex;
  align-items: center;
}

.hiddenElement {
  height: 0.1px;
  width: 0.1px;

  opacity: 0;

  position: absolute;
}

.fileName {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.label {
  margin-right: 25px;
  width: 80px;
  text-align: right;
}

.error {
  color: color("accent", "alert");
  padding: 10px;
  max-width: 300px;
}