@mixin width-breakpoints {
  @media only screen and (min-width: 480px) {
    width: 100%;
  };
  @media only screen and (min-width: 768px) {
    width: 75%;
  };
  @media only screen and (min-width: 1024px) {
    width: 60%;
  };
  @media only screen and (min-width: 1440px) {
    width: 50%;
  }
};
@mixin max-width-breakpoints {
  @media only screen and (min-width: 480px) {
    max-width: 100%;
  };
  @media only screen and (min-width: 768px) {
    max-width: 75%;
  };
  @media only screen and (min-width: 1024px) {
    max-width: 60%;
  };
  @media only screen and (min-width: 1024px) {
    max-width: 50%;
  };
};