@import "../../../../../../styles/color-functions";
@import "../../../../../../styles/font-functions";
@import "../../../../../../styles/media-functions";

.container{
  position: relative;
  box-sizing: border-box;
  padding: 30px;

  @include width-breakpoints;
  max-width: 800px;
}

.heading {
  border-bottom: 2px solid color("accent", "disabled");
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.titles {
  display: block;
}

.category {
  @include font-setting("lg")
}

.title {
  @include font-setting("base")
}

.typeSelectorLabel { 
  @include font-setting("base-bold");
  margin-bottom: 10px;
}

.typeSelectorButtons {
  padding-bottom: 40px;
}

.amountInput {
  color: color("text","dark");
  font-family: Manrope;
  line-height: 1.3rem;
  font-size: 2.4rem !important;

  width: 300px;
  
  padding: 0 5px !important;

  &::placeholder {
    font-weight: lighter !important;
  }
}

.img {
  height: 120px;
  width: 200px;
  overflow: hidden;

  display:flex;
  justify-content: center;

  position: absolute;
  bottom: -30px;
  right: 20px;

  
  background-color: white;
  box-shadow: 0px 4px 4px 1px rgba(0,0,0, 0.25);
  border-radius: 5px;

  img {
    object-fit: cover;
    margin: 15px;
    overflow: hidden;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
}