@import "../../../styles/color-functions";

.buttonCard {
  width: 100%;
}

.card {
  background-color: color("background", "light") !important;
  border-radius: 16px !important;
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.10);
  color: color("text", "dark") !important;
  margin: 20px !important;
  padding: 20px !important;
}