@import "../../../../../../styles/color-functions";
@import "../../../../../../styles/font-functions";



.container {
  display: flex;
  align-items: center;

  height: 130px;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;

  background: color("background", "dark");

  color: color("text","light");

  position: sticky;
  top:0;
  z-index: 100;

  @media only screen and (max-width: 500px) {
    height: 75px;
  }
}

.previousStep {
  display: flex;
  align-items: center;
  
  font-size: 3rem;
  line-height: 3em;

  cursor: pointer;
}

.center {
  flex-grow: 1;

  @media only screen and (max-width: 500px) {
    & > * {
      display: inline
    }
  }
}

.name{
  @include font-setting("md");
  line-height: 1.5rem;
  padding-right: 20px;
}

.balance {
  @include font-setting("xl");
}

.portrait {
  width: 100px;
  height: 100px;
  object-fit: cover;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  border-radius: 10000px;
  
  margin-right: 20px;
  & > img {
    height: 100px;
  }

  @media only screen and (max-width: 500px) {
    display: none;
  }
}