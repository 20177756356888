@import "../../../../../../styles/color-functions";
@import "../../../../../../styles/font-functions";



$card-width: 250px;

.card {

  width: $card-width;
  box-sizing: border-box;
  padding: 10px 0;
  margin: 20px;

  cursor: pointer;

  box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
  border-radius: 10px;

  text-align: center;


}

.category {
  @include font-setting("lg");
}


.thumbnail {
  height: 100px;
  overflow: hidden;
  
  display: flex;
  align-items: center;

  img {
    width: $card-width;
  }
}

.title {
  @include font-setting("base");
  padding-top: 10px,
}