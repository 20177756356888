// @use "./App.scss"

$breakpoint-tablet: 768px;

$header-background-color: rgb(233,237,242);
$header-icon-background-color: #3D3D6E;
$header-height-mobile: 75px;

$sideNav-width: 300px;

.root {
  min-height: 100vh;
  display: grid;
  font-family: Manrope;
  grid-template-columns: $sideNav-width 2fr;
  grid-template-areas: 
  "sideNav content"  
}

.logo {
  grid-area: logo;
  height: 100%;
  width: 100%;
  background-color: rgb(33,35,85);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoImg {
  width: 60% !important;
}

.header {
  grid-area: header;
  background-color: $header-background-color
}

.sideNav {
  grid-area: sideNav;
  overflow: auto;
  background-color: #3D3D6E;
  
  // hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none
  }
}

.content {
  grid-area: content;
  position: relative;
  overflow: hidden;
}

.hamburgerNav {
  display: none;
  grid-area: hamburger;
  background-color: $header-icon-background-color;
}

.logoutDropdown {
  grid-area: logout;
  background-color: $header-background-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: $breakpoint-tablet) {
  .root {
    grid-template-columns: 75px;
    grid-template-rows: $header-height-mobile auto;
    grid-template-areas:    
    "hamburger header" 
    "content content";
  }
  .hamburgerNav {
    display: unset;
  }
  .logoutDropdown, .sideNav{
    display: none;
  }
}