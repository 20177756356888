@import "../../../styles/color-functions";
@import "../../../styles/font-functions";

.closeButton {
  @include font-setting("xl");
  color: color("text", "dark");
  text-align: center;
  
  background-color: color("background", "light");
  
  z-index: 1000;
  width: 50px;
  height: 50px;
  border-radius: 1000px;
  border: 1px solid color("background", "dark");

  
  cursor: pointer;
}

.small {
  @include font-setting("md");
  line-height: 25px;
  width: 25px;
  height: 25px;
}