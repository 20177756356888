@import "../../../styles/font-functions";
@import "../../../styles/color-functions";


.backChevron {
  background: inherit;
  color: inherit;
  border: none;
  display: block;
  height: 100%;
  
  @include font-setting("xl");
  line-height: 100%;
  
  height: 100%;
  margin: auto;

  padding-inline: 10px;
  cursor: pointer;

  display: flex;
  align-items: center;

  &.dark {
    color: color("text", "dark");
  }
}
