//  Colors
//  Defined in: https://www.figma.com/file/rTUYMYWdGLtfA3nepR5ZZe/

// Whites 
$White: #FFFFFF;
$Beige01: #FCFAF7;
$OffWhite01: #FBFBFF;

// Dark Purples 
$DarkPurple01: #1C1C47;
$DarkPurple02: #1F2152;
$DarkPurple03: #595985;
$DarkPurple04: #3D3D6E;
$DarkPurple05: #33305C;
$DarkPurple06: #2B2E69;

// Light Purples 
$LightPurple01: #F0F0FF;
$LightPurple02: #D9D9FF;
$LightPurple03: #EDEDFA;

// Purples 
$Purple01: #7378E8;
$Purple02: #B5B8EB;
$Purple03: #A19ED6;
$Purple04: #CACCEB;
$Purple05: #7373E814;
$Purple06: #7373E805;

// Light Greys 
$LightGrey01: #9696BA;
$LightGrey02: #A6A6BF;
$LightGrey03: #E3E3FA;
$LightGrey04: #E3E3FA66;
$LightGrey05: #E3E3E3;

// Misc
$Red01: #F5577A;
$DarkGrey01: #363636;
$Facebook: #4073E5;
$Green01: #7FE8B2;
$Grey01: #9494A8;

// ------------------

$theme-colors: (
  'text': (
    'light': $White,
    'dark': $DarkPurple02,
  ),
  'primary': (
    'base': $Purple01,
  ),
  'disabled': (
    'base': $LightGrey02,
    'light': $LightGrey03
  ),
  'background': (
    'light': $OffWhite01,
    'light-alt-1': $LightPurple02,
    'light-alt-2': $LightPurple03,
    'dark': $DarkPurple02
  ),
  'accent': (
    'alert': $Red01,
    'success': $Green01,
    'disabled': $Grey01,
  )
);
