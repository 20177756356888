.list {
  display: flex;
  flex-wrap: wrap;
  margin:auto;
  /*
  * Keep component hidden initially. 
  * Component will set visible once it sets width.
  */
  visibility:hidden;
}
