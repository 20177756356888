@import "../../../../../../../../styles/color-functions";

.modal {
  box-sizing: border-box;

  background-color: color("background", "light");

  border-radius: 10px;

  padding: 40px 80px;

  outline:  none;

}
