.pdfContainer {
  height: 500px;
  width: 100%;
  overflow: auto;
  
  box-sizing: border-box;
}

.img {
  width: 100%;
  height: 500px;
  object-fit: contain;
}

.buttonContainer {
  padding: 20px;
  text-align: center;
}