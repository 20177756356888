@import "../../../../../../styles/font-functions";
@import "../../../../../../styles/media-functions";
@import "../../../../../../styles/color-functions";

.confirmation {
  
  & > * {
    margin-block: 10px;
  }

  @include max-width-breakpoints;
}

.requestType {
  @include font-setting("xxl");
  text-align: center;
}

.heading {
  margin-bottom: 30px;
}

.amount {
  font-size: 3rem;
}

.answer {
  margin-bottom: 30px;
}

.answerValue {
  @include font-setting('lg');
  font-weight: normal;
}

.content{  
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: color("background", "light-alt-2");

  width: 400px;
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
  flex-direction: column;}