@import "../../../../../../styles/color-functions";
@import "../../../../../../styles/media-functions";
@import "../../../../../../styles/font-functions";

.debrief {
  background: color("background", "dark");
  color: color("text","light");
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.backgroundBeacon {
  pointer-events: none;    
  user-select: none;
  height: 600px;

  position: absolute;
  left: 30%;
  top: 0;

}

.inner {
  position: relative;

  max-width: 400px;
  
  box-sizing: border-box;
  margin:auto;
  padding-top: 70px;
  padding-inline: 20px;
}

.portrait {
  height: 150px;
  width: 150px; 
  object-fit: cover;
  border-radius: 100px;
  overflow: hidden;
  text-align: center;
  
  display: flex;
  justify-content: center;
  
  margin-bottom: 40px;
  
  & > img {
    height: 150px;
  }
}

.textContent {
  @include font-setting("xl");
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 2px solid color("accent","disabled");
}
.transactionId {
  @include font-setting("md");
  margin-top: 40px;
}