@import "../../../../styles/color-functions";
@import "../../../../styles/font-functions";

.container {
  height:  100%;
  width: 100%;
  overflow: auto;


  background-color: color("background", "light");

  color: color("text","dark");
  
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  z-index: 100;
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0 0px 0;
}

.btn {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  border-radius: 32px !important;
  background-color: color("primary", "base") !important;
  color: color("text", "light") !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: font("primary", "main");
  text-transform: none !important;
}

.btnCancel {
  background-color: color("background", "light") !important;
  color: #7378E8 !important;
}