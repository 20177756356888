.root {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  height: 100vh;
}

.logo {
  filter: invert(100%)
}

.title {
  text-align: center;
  font-size: 3rem;
}

.subtitle {
  font-size: 2rem;
}

.buttonsDiv {
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
}

.homeButton {
  text-decoration: none;
}

.slideContainer {
  width: 100vw;
  height: 35px;
  overflow: hidden;
  position: relative;
}

.slide {
  animation: slide 120s infinite;
  height: 35px;
  min-width: 200vw;
  position: absolute;
  right: 0;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  35% {
    transform: translateX(50%);
  }
  50% {
    transform: translateX(0%);
  }
  65% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0%);
  }
}