@import "../../../styles/color-functions.scss";

.label {
  font-size: 0.8em;
  line-height: 1.5em;
  font-weight: bold;

  position: absolute;
  transform: translateY(-50%);
  cursor: text;

  padding: 0em 0.5em;
  box-sizing: content-box;

  border-radius: 5px;

  user-select: none;
}

.input {
  font-size: inherit;
  color: color("text", "dark");

  padding: 0.55em 1.5em;
  margin-top: 0.8em;

  border: 2px solid;
  border-radius: 15px;
  outline: none;

  min-width: 16em;

  font-family: Manrope, sans-serif;
}