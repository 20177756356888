@import "../../../styles/font-functions";
@import "../../../styles/color-functions";

.button {
  font-family: Manrope, sans-serif;

  display: inline-block;
  cursor: pointer;

  background-color: unset;

  border: none;
  border-bottom: 1px solid color("text", "dark");
  padding: 0.2em;
  
  color: color("text", "dark");
  
  @include font-setting("sm");
  
  &.light {
    border-bottom: 2px solid color("text", "light");
    
    color: color("text", "light");

  }
}