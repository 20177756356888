@import "../../styles/color-palettes.scss";
@import "../../styles/color-functions.scss";

.root {
  
  overflow: auto;
  height: 100vh;
  width: 100vw;
  max-width: 100%;

  background: $LightGrey03;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Manrope, sans-serif ;

  & * {
    box-sizing: border-box;

  }
}

.login {
  display: flex;
  overflow: hidden;
  
  max-height: 800px;
  max-width: 1200px;
  bottom: 35%;
  
  background: $DarkPurple03;
  
  box-shadow: 0 20px 46px 15px #00000050;
  
  color: color("text","light");
  
  @media only screen and (max-width: 1000px) {
    width: 95%;
    height: 95%;
    border-radius: 30px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    max-height: unset;
    border-radius: 0px;
  }

  @media only screen and (min-width: 1000px) {
    width: 80%;
    height: 90%;
    border-radius: 30px;
  }


}

.loginDivVideoWrapper {
  position: relative;
  overflow: hidden;

  width: 100%;
}

.loginVideoDiv {
  position: relative;

  height: 100%;
  max-height: 100%;
  width: 100%;


  display: flex;
  align-items: center;
  overflow: hidden;

  z-index: 100;
}

.loginVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 35%;
}

.opacity {
 position: absolute;
  height: 100%;
  width: 100%;

  background-color: color("primary", "base");
  opacity: 0.3;
}

.logo {
  position: absolute;
  z-index: 100;
  left: 50%;
  bottom: 35%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mobileLogo {
  display: none;
  flex-direction: column;
  align-items: center;
  width: max-content;
}

.logoCaption {
  font-size: 0.80rem;
  text-align: center;
  margin-top: 10px;
}

.loginFormDiv {
  position: relative;
  overflow: hidden auto;
  z-index: 1;
}
.greetingText {
  width: min-content;
  margin: auto;
  margin-top: #{"min(30%, 80px)"};
}

.welcomeBack {
  font-size: 2.5rem;
  font-weight: bold;

  white-space: nowrap;
}

.partnerSignIn {
  font-size: 1.5rem;
  font-weight: bold;
}

.inputGroup {
  margin-block: 10px;
}


@media only screen and (max-width: 767px) {
  .root {
    align-items: unset;
    background: $DarkPurple03;
    height: 100vh;
  }
  
  .login {
    position: fixed;
    top:0;
    display: block;
    overflow: auto;
  }

  .greetingText {
    margin-top: 0px;
  }

  .loginDivVideoWrapper {
    position: absolute;
    top: 0;
    height: 100vh;
  }

  .loginFormDiv {
    position: absolute;
    z-index: 10;
    min-height: 100vh;
    padding-top: 130px;
  }

  .logo {
    display: none;
  }

  .mobileLogo {
    display: flex;
    position: absolute;
    z-index: 200;
    transform: translate(-50%, 0%);
    left: 50%;
  }
}