html {
  font-size: 16px; 
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Manrope';
  font-weight: 100;
  font-style: normal;
  src: url('./fonts/manrope-light.woff');
  src: local('Manrope'), url('./fonts/manrope-light.woff') format('opentype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 300;
  font-style: normal;
  src: url('./fonts/manrope-regular.woff');
  src: local('Manrope'), url('./fonts/manrope-regular.woff') format('opentype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 500;
  font-style: normal;
  src: url('./fonts/manrope-medium.woff');
  src: local('Manrope'), url('./fonts/manrope-medium.woff') format('opentype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 600;
  font-style: normal;
  src: url('./fonts/manrope-semibold.woff');
  src: local('Manrope'), url('./fonts/manrope-semibold.woff') format('opentype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 700;
  font-style: normal;
  src: url('./fonts/manrope-bold.woff');
  src: local('Manrope'), url('./fonts/manrope-bold.woff') format('opentype');
}

@font-face {
  font-family: 'Manrope';
  font-weight: 900;
  font-style: normal;
  src: url('./fonts/manrope-extrabold.woff');
  src: local('Manrope'), url('./fonts/manrope-extrabold.woff') format('opentype');
}

@font-face {
  font-family: 'FontAwesome5';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/font-awesome-5-brands-regular.otf');
  src: local('FontAwesome5Pro'), url('./fonts/font-awesome-5-brands-regular.otf') format('opentype');
}

@font-face {
  font-family: 'FontAwesome5Pro';
  font-weight: 300;
  font-style: normal;
  src: url('./fonts/font-awesome-5-pro-light.otf');
  src: local('FontAwesome5Pro'), url('./fonts/font-awesome-5-pro-light.otf') format('opentype');
}

@font-face {
  font-family: 'FontAwesome5Pro';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/font-awesome-5-pro-regular.otf');
  src: local('FontAwesome5Pro'), url('./fonts/font-awesome-5-pro-regular.otf') format('opentype');
}

@font-face {
  font-family: 'FontAwesome5Pro';
  font-weight: 900;
  font-style: normal;
  src: url('./fonts/font-awesome-5-pro-solid.otf');
  src: local('FontAwesome5Pro'), url('./fonts/font-awesome-5-pro-solid.otf') format('opentype');
}

.fa {
    display: inline-block;
    /* font-style ont-variant font-weight font-size/line-height font-family*/
    font: normal normal normal 14px/1 FontAwesome5;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fal {
    display: inline-block;
    font: normal normal 300 14px/1 FontAwesome5Pro;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.far {
    display: inline-block;
    font: normal normal 400 14px/1 FontAwesome5Pro;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fas {
    display: inline-block;
    font: normal normal 900 14px/1 FontAwesome5Pro;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-backspace:before {
    content: "\f55a";
}

.fa-book:before {
    content: "\f02d";
}

.fa-calendar-alt:before {
    content: "\f073";
}

.fa-check:before {
    content: "\f00c";
}

.fa-check-circle:before {
    content: "\f058";
}

.fa-circle:before {
    content: "\f111";
}

.fa-chevron-left:before {
    content: "\f053";
}
.fa-chevron-right:before {
    content: "\f054";
}

.fa-clipboard-check:before {
    content: "\f46c";
}

.fa-clock:before {
    content: "\f017";
}

.fa-comments-alt:before {
    content: "\f4b6";
}

.fa-donate:before {
    content: "\f4b9";
}

.fa-ellipsis-h:before {
    content: "\f141";
}

.fa-ellipsis-v:before {
    content: "\f142";
}

.fa-exclamation-circle:before {
    content: "\f06a";
}

.fa-hands-helping:before {
    content: "\f4c4";
}

.fa-hands-usd:before {
    content: "\f4c5";
}

.fa-hand-heart:before {
    content: "\f4bc";
}

.fa-heart:before {
    content: "\f004";
}

.fa-lock-alt:before {
    content: "\f30d";
}

.fa-money-bill:before {
    content: "\f0d6";
}

.fa-pencil-paintbrush:before {
    content: "\f618";
}

.fa-pencil:before {
    content: "\f040";
  }

.fa-plus:before {
    content: "\f067";
}

.fa-plus-circle:before {
    content: "\f055";
}

.fa-sync:before {
    content: "\f021";
}

.fa-tachometer-alt-fast:before {
    content: "\f625";
}

.fa-tachometer-alt-slowest:before {
    content: "\f628";
}

.fa-tachometer-alt:before {
    content: "\f3fd";
}

.fa-briefcase-medical:before {
    content: "\f469";
}

.fa-search:before {
    content: "\f002";
}

.fa-sync:before {
    content: "\f021";
}

.fa-times:before {
    content: "\f00d";
}

.fa-times-circle:before {
    content: "\f057";
}

.fa-hand-heart:before {
    content: "\f4bc";
}

.fa-clipboard-check:before {
    content: "\f46c";
}

.fa-chevron-right:before {
    content: "\f054";
}

.fa-chevron-up:before {
    content: "\f077";
}

.fa-chevron-down:before {
    content: "\f078";
}

.fa-check:before {
    content: "\f00c";
}

.fa-check-circle:before {
    content: "\f058";
}

.fa-circle:before {
    content: "\f111";
}

.fa-arrow-alt-right-square:before {
    content: "\f352";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-bars:before {
    content: "\f0c9";
}

.fa-eye:before {
    content: "\f06e";
}

.fa-trash-alt:before {
    content: "\f2ed"
}

.fa-trash:before {
    content: "\f1f8"
}

.fa-arrow-circle-up::before {
    content: "\f0aa";   
}

/* touchpoint question icons dynamically defined */
.fa-home:before {
    content: "\f015";
}
.fa-hand-holding-usd:before {
    content: "\f4c0";
}
.fa-apple-alt:before {
    content: "\f5d1";
}
.fa-car:before {
    content: "\f1b9";
}
.fa-lightbulb:before {
    content: "\f0eb";
}
.fa-user-lock:before {
    content: "\f502";
}
.fa-user-plus:before {
    content: "\f234";
}
.fa-user-tie:before {
    content: "\f508";
}
.fa-users:before {
    content: "\f0c0";
}
.fa-notes-medical:before {
    content: "\f481";
}

.fa-comment-alt-dots:before {
    content: "\f4a3";
}

.fa-comment-alt-smile:before {
    content: "\f4aa";
}

.fa-file:before {
    content: "\f15b"
}

.fa-external-link-alt:before {
    content: "\f35d"
}

.fa-file-download:before {
    content:"file-download"
}

.fa-edit:before {
    content: "edit"
}

.fa-image::before {
    content: "image"
}

.fa-info::before {
    content: "\f129"
}

.fa-arrow-up::before {
    content: "\f062"
}
      
.fa-arrow-left::before {
    content: "arrow-left"
}

.fa-arrow-to-bottom::before {
    content: "\f33d";
}

.fa-question-circle::before {
    content: "\f059";
}

.fa-question::before {
    content: "\f128";
}

.fa-smile::before {
    content: "\f118";
}

.fa-frown::before {
    content: "\f119";
}

.fa-rocket::before {
    content: "\f135";
}

.fa-paw::before {
    content: "\f1b0";
}

.fa-magic::before {
    content: "\f0d0";
}

.fa-camera::before {
    content: "\f030";
}

.fa-pen::before {
    content: "\f304";
}

.fa-long-arrow-right::before {
    content: "\f178";
}

.fa-cog::before {
    content: "\f013";
}

.fa-sign-out::before {
    content: "\f08b";
}

.fa-sign-out-alt::before {
    content: "\f2f5";
}

.fa-hands-heart::before {
    content: "\f4c3";
}

.fa-bell::before {
    content: "\f0f3";
}

.fa-credit-card::before {
    content: "\f09d";
}

.fa-store::before {
    content: "\f54e";
}

.fa-minus::before {
    content: "\f068";
}

.fa-folder-open::before {
    content: "\f07c";
}

.fa-history::before {
    content: "\f1da";
}
  
.fa-envelope::before {
    content: "\f0e0";
}

.fa-id-card::before {
    content: "\f2c2";
}

.fa-chart-pie::before {
    content: "\f200";
}

.fa-info-circle::before {
    content: "\f05a";
}

.fa-arrow-right::before {
    content: "\f061";
}

.fa-crop::before {
    content: "\f125";
}

.fa-crop-alt::before {
    content: "\f565";
}

.fa-bookmark::before {
    content: "\f02e";
}

.fa-lock::before {
    content: "\f023";
}

.fa-random::before {
    content: "\f074";
}

.fa-user::before {
    content: "\f007";
}

.fa-usd-circle::before {
    content: "\f2e8";
}

.fa-keyboard::before {
    content: "\f11c";
}

.fa-birthday-cake::before {
    content: "\f1fd";
}

.fa-comment::before {
    content: "\f075";
}

.fa-phone::before {
    content: "\f095";
}

.fa-spinner::before {
    content: "\f110";
}

@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  
.fa-spin {
    animation-name: fa-spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation: fa-spin 2s infinite linear; /* For WebKit browsers */
    animation: fa-spin 2s infinite linear; /* Standard */
}

.fa-book-open-reader::before {
    content: "\f5da";
}

.fa-bolt::before {
    content: "\f0e7";
}

.fa-sticky-note::before {
    content: "\f249";
}

.fa-user-tag::before {
    content: "\f507";
}

.fa-user-friends::before {
    content: "\f500";
}

.fa-user-check::before {
    content: "\f4fc";
}