@import "../../../styles/color-palettes.scss";

.formInputsDiv{
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
  padding-top: 20px;
}

.buttonGroupContainer {
  width: 200px;
  margin:auto;
}

.buttonGroup  {
  max-height: 250px;
  width: 200px;
  margin: auto;
  overflow: auto;

  display: flex;
  flex-direction: column;

  & > * {
    min-height: min-content;
  }
}

.form {
  width: 100%;

  margin:auto;
  margin-top: 70px;

  position: relative;  
}

.animatedForm {
  top: 0;
  position: absolute;
}

.radioGroup {
  font-size: 1.1rem;
  margin: auto;
  width: max-content;
}

.inputGroup {
  margin-inline: 4px;
}

.formSectionTitle {
  margin: 30px 0px 10px 0px;
  font-weight: bold;
}

.formInput {
  padding: 0.55em 1.5em;
  margin-block: 5px;
  width: 100%;
  border-radius: 10px;
  border: none;
  font-family: inherit;
  font-size: 1rem;

  &:not([value=""]):not(:focus):invalid{
    border: 1px solid $Red01;
    padding: 6px 19px;
  }
}